<template>
  <div class="col-12">
    <div class="content-card">
      <div class="card-header card-header-flex rounded-t-lg bg-brand card-header-flex-center text-center font-bold p-3 cursor-pointer" @click="toggle()">
        <p>
          <font-awesome-icon icon="caret-up" class="icon" v-show="collapsible" /><font-awesome-icon icon="caret-down" class="icon" v-show="!collapsible" /> <b>{{ data.name }}</b>
        </p>
      </div>
      <div class="card-content text-center rounded-b-lg bg-card-content" v-show="collapsible">
        <!-- <embed v-if="data.file.type == 'application/pdf'" :src="'https://drive.google.com/viewerng/viewer?embedded=true&url=https://api.stadtbahnfahrer.club/assets/'+data.file.id" width="100%" height="400">    -->
        <div v-if="data.file">
          <div v-if="data.file.type == 'application/pdf'"><a :href="'https://api.stadtbahnfahrer.club/assets/'+data.file.id+'?access_token='+user.token"><object :data="'https://api.stadtbahnfahrer.club/assets/'+data.file.id+'?access_token='+user.token" type="application/pdf" class="w-full h-96"></object><!--<pdf :src="'https://api.stadtbahnfahrer.club/assets/'+data.file.id"></pdf>--></a></div>
          <p v-else><small>(Vorschau nicht verfügbar)</small></p>               
          <div class="space"></div>
          <p class="px-3 pt-3"><a class="link" :href="'https://api.stadtbahnfahrer.club/assets/'+data.file.id+'?access_token='+user.token"><font-awesome-icon icon="file-pdf" class="icon" /> Vollständige Datei herunterladen ({{data.file.type}})</a></p>     
        </div>
        <p>{{data.customtext}}</p>
        <p class="px-3 py-3"><a class="link" @click="openReportModal" v-show="reportable"><font-awesome-icon icon="flag" class="icon" /> Änderungen einreichen</a></p>                                
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import { mapGetters } from "vuex";

export default {
  name: "FileItem",
  props: {
    data: null,
    reportable: false,
    image: String,
    canEdit: Boolean,
  },
  components: {
    pdf
  },
  data() {
    return {
      collapsible: false
    }
  },
  computed: {
    ...mapGetters({
      user: "getUser"
    }),
  },
  methods: {
    toggle() {
      if(this.collapsible) {
        this.collapsible = false
      } else {
        this.collapsible = true
      }
    },
    openReportModal(){
      this.$emit('openReportModal',this.data);
    }
  },
};
</script>
